import client from "../utils/AxiosInterceptor";


const getPeerLogList = async (value) => {
  const { data } = await client.get('/Report/LogPerWeek', value);
  return data;
};

const getCountLog = async (value) => {
  const { data } = await client.get('/Report/CountLog', value);
  return data;
};

const getLastJobDone = async (value) => {
  const { data } = await client.get('/Report/LastJobDone', value);
  return data;
};

const getCountJobDone = async (value) => {
  const { data } = await client.get('/Report/CountJobDone', value);
  return data;
};

export { getPeerLogList, getCountLog, getLastJobDone, getCountJobDone };