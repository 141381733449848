import React from 'react';

const PropertyItem = ({ icon, text, title }) => {
  return (
    <div className='flex items-center justify-center flex-col border p-1 rounded bg-white' style={{ boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' }}>
      <div className='my-4 mx-auto text-stone-600'>{icon}</div>
      <p className='text-sm font-medium  text-cyan-700 my-2'>{title}</p>
      <p className='text-sm font-medium text-slate-700 my-4 text-center w-10/12 mx-auto leading-normal'>{text}</p>
    </div>
  );
};

export default PropertyItem;