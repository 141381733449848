import CronItem from "../CronItem/CronItem";
import ModalPermission from "../ModalPermission/ModalPermission";
import { useState } from 'react';

const CronContainer = ({ toggleTab, changeHandlerCron, }) => {


  const [isShowModal, setIsShowModal] = useState(false);
  const userPremission = localStorage.getItem("userType");


  const checkPremissionFree = () => {
    if (userPremission === "0") {
      setIsShowModal(true);
    }
    else if (userPremission === "1") {
      setIsShowModal(true);
    }
    else if (userPremission === "2") {
      toggleTab(0);
    }
  };

  const checkPremissionMid = () => {
    if (userPremission === "0") {
      setIsShowModal(true);
    }
    else if (userPremission === "1") {
      toggleTab(1);
    }
    else if (userPremission === "2") {
      toggleTab(1);
    }
  };


  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-0.5 md:gap-y-2 mx-auto w-11/12 my-10">
      {
        isShowModal && (
          <ModalPermission setIsShowModal={setIsShowModal}
            isShowModal={isShowModal} />
        )
      }
      <CronItem onClick={checkPremissionFree}
        changeHandlerCron={changeHandlerCron}
        number={"1."}
        title="بر اساس دقیقه اجرا شود" />
      <CronItem onClick={checkPremissionMid}
        changeHandlerCron={changeHandlerCron}
        number={"2."}
        title="بر اساس ساعت اجرا شود" />
      <CronItem changeHandlerCron={changeHandlerCron}
        number={"3."}
        title="هر روز اجرا شود"
        onClick={
          () => toggleTab(2)
        } />
      <CronItem changeHandlerCron={changeHandlerCron}
        number={"4."}
        title="در روز معینی از هفته در ساعت معینی اجرا شود"
        onClick={
          () => toggleTab(3)
        } />
      <CronItem changeHandlerCron={changeHandlerCron}
        number={"5."}
        title="روز های کاری اجرا شود"
        onClick={
          () => toggleTab(4)
        } />
      <CronItem changeHandlerCron={changeHandlerCron}
        number={"6."}
        title="چند بار در هفته اجرا شود"
        onClick={
          () => toggleTab(5)
        } />
      <CronItem changeHandlerCron={changeHandlerCron}
        number={"7."}
        title="هر چند ماه یکبار در روز معینی اجرا شود"
        onClick={
          () => toggleTab(6)
        } />
      <CronItem changeHandlerCron={changeHandlerCron}
        number={"8."}
        title="هر چند ماه یکبار در چندمین هفته اجرا شود"
        onClick={
          () => toggleTab(7)
        } />
      <CronItem changeHandlerCron={changeHandlerCron}
        number={"9."}
        title="یکبار در سال اجرا شود"
        onClick={
          () => toggleTab(8)
        } />
    </div>
  );
};

export default CronContainer;
