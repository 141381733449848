import client from '../utils/AxiosInterceptor';




const getJobList = async (value) => {
  const { data } = await client.get(`/Job/List?page=${value.page}&take=${value.take}`);
  return data;
};


const deleteJob = async (value) => {
  const { data } = await client.post(`/Job/Delete?id=${value}`);
  return data;
};

const getJobListWidthId = async (value) => {
  const { data } = await client.get(`/Job/Get?id=${value}`);
  return data;
};

export { getJobList, deleteJob, getJobListWidthId };