import React from 'react';
import { Link } from 'react-router-dom';
import chekane from '../../../assets/Images/chekane.png';
import whatsapp from '../../../assets/Images/whatsapp.svg';

const FooterContainer = () => {
  return (


    <footer id="contactus" className="bg-cyan-50 mt-12 ">
      <div className="grid grid-cols-2 gap-8 py-8  md:grid-cols-3 w-11/12 mx-auto">
        <div className='order-1'>
          <h2 className="mb-6 text-base font-semibold text-gray-500 uppercase ">چکانه</h2>
          <ul className="text-gray-500 ">
            <li className="mb-4">
              <p className='text-slate-600 font-medium text-sm leading-loose text-justify'>برنامه ریزی دقیق ، اجرای بموقع کارها ، ذخیره ی تمام لاگ ها و مدیریت آنها از وظایف چکانه می باشد شما می توانید به راحتی با چکانه کارهای خود را مدیریت کنید، لاگ ها را مشاهده کنید و از گزارش های آن استفاده کنید</p>
            </li>

          </ul>
        </div>
        <div className='order-3 sm:order-2 lg:text-center lg:w-4/12 ml-auto sm:mx-auto'>
          <h2 className="mb-6 text-base font-semibold text-gray-500 uppercase lg:text-right">پلن ها</h2>
          <ul className="text-gray-500 ">
            <li className="mb-4 lg:text-right">
              <Link to="#" className="text-slate-600 font-medium text-sm ">پلن رایگان</Link>
            </li>
            <li className="mb-4 lg:text-right">
              <Link to="#" className="text-slate-600 font-medium text-sm ">پلن ویژه</Link>
            </li>
            <li className="mb-4 lg:text-right">
              <Link to="#" className="text-slate-600 font-medium text-sm ">پلن سازمانی</Link>
            </li>

          </ul>
        </div>
        <div className='order-2 sm:order-2 flex items-center justify-center flex-col'>
          <img src={chekane} alt="Chekane Logo" />
          <div className="grid grid-cols-3 gap-x-8 w-6/12 md:w-4/12 pt-10">
            <Link to="#">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-cyan-600 hover:text-cyan-800 active:scale-95">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
              </svg>
            </Link>
            <Link to="#">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-cyan-600 hover:text-cyan-800 active:scale-95">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
              </svg>

            </Link>
            <Link to="#">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-cyan-600 hover:text-cyan-800 active:scale-95">
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
      <div className="py-6 px-4 bg-white shadow-sm border mx-auto rounded-lg md:flex md:items-center md:justify-between">
        <div className='flex items-center justify-start flex-col '>
          <p className="text-sm text-gray-500  sm:text-center leading-6">
            آدرس : تهران، یوسف آباد، خیابان سی و یکم، نبش ابن ‌سینا، پلاک 111، ساختمان هوشمند کهکشان نور . تلفن: 02188719294
          </p>
        </div>
        <p className="text-sm text-gray-500  sm:text-center mt-2 sm:mt-0">
          2022 © Copyright Chekane.ir
        </p>
      </div>
    </footer>


  );
};

export default FooterContainer;