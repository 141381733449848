import { useState } from 'react';
import CronWrapper from "../CronWrapper/CronWrapper";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

const CronWeekDays = ({ toggleTab, cronValue, setCronValue, }) => {

  const [value, setValue] = useState(new Date());
  const calcedTime = () => {
    let d = new Date();
    let [hours, minutes] = cronValue.runTimeDisplay.split(':');
    d.setHours(+ hours);
    d.setMinutes(+ minutes);
    return d;
  };
  return (
    <CronWrapper onClick={
      () => toggleTab(99)
    }>
      <div>
        <p className="leading-normal text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0">
          <span className="text-xs text-purple-500 ml-2">3.</span>
          هر روز هفته در یک زمان مشخص اجرا خواهد شد
        </p>
        <p className="text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0">
          <span className="text-xs text-purple-500 ml-2">مثال:
          </span>
          هر روز در ساعت 13 اجرا خواهد شد
        </p>
      </div>
      <div className="flex items-center justify-start ml-auto">
        <DatePicker disableDayPicker inputclassName="py-2 px-4 border outline-none rounded-lg bg-melo-white text-center text-slate-700 text-xs md:text-sm cursor-pointer hover:bg-zinc-100" format="HH:mm"
          // eslint-disable-next-line react/jsx-key
          plugins={[<TimePicker />]}
          calendar={persian}
          locale={persian_fa}
          calendarPosition="bottom-right"
          name="runTimeDisplay"
          value={
            calcedTime()
          }

          onChange={
            (value) => {
              let d = value.toDate();
              let times = d.toTimeString().split(' ')[0].split(':');
              let time = `${times[0]
                }:${times[1]
                }`;
              setCronValue((inputValue) => ({
                ...inputValue,
                runTimeDisplay: time,
              }));
            }
          } />
      </div>
    </CronWrapper>
  );
};

export default CronWeekDays;
