import client from '../utils/AxiosInterceptor';


const getCronExpression = async (value) => {
  const { data } = await client.post('/Job/CronExpression', value);
  return data;
};

const editCronExpression = async (value) => {
  const { data } = await client.post('/Job/Edit', value);
  return data;
};

export { getCronExpression, editCronExpression };