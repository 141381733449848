import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({setShowModal, showModal}) => {
  return (
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded ">
      <div className="flex flex-wrap justify-between items-center mx-auto w-11/12">
        <Link to="/" className="flex items-center">
          <span className="self-center text-xl font-semibold whitespace-nowrap ">Chekane</span>
        </Link>
        <div className="flex md:order-2">
          <button onClick={() => setShowModal(!showModal)} type="button" className="text-white bg-cyan-700 hover:bg-cyan-800 focus:ring-4 focus:outline-none focus:ring-cyan-300 font-medium rounded-md hover:translate-y-1 duration-500 text-sm px-4 py-2 text-center mr-3 md:mr-0 ">ورود / ثبت نام</button>

        </div>
        <div className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1" id="navbar-cta">
          <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white ">
            <li>
              <a href="#home" className="block py-2 pl-4 pr-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-cyan-700 mx-7 md:p-0 ">خانه</a>
            </li>
            <li>
              <a href="#property" className="block py-2 pl-4 pr-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-cyan-700 md:p-0 ">ویژگی ها</a>
            </li>
            <li>
              <a href="#plans" className="block py-2 pl-4 pr-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-cyan-700 md:p-0 ">پلن ها</a>
            </li>
            <li>
              <a href="#aboutus" className="block py-2 pl-4 pr-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-cyan-700 md:p-0 ">درباره ما</a>
            </li>
            <li>
              <a href="#contactus" className="block py-2 pl-4 pr-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-cyan-700 md:p-0 ">تماس با ما</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  );
};

export default Navbar;