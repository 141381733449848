import * as api from '../apis/CronExpression';
import { useMutation } from 'react-query';
import Swal from 'sweetalert2';
import { queryClient } from '../index';
import toast from 'react-hot-toast';

const useCronExpression = (value) => {
  return useMutation(api.getCronExpression, {
    onSuccess: (response, values) => {
      if (response.status) {
        queryClient.setQueryData(response);
      } else {
        console.log('سرور با خطا مواجه شد');
      }
    }
  });
};

const useEditCronExpression = (value) => {
  return useMutation(api.editCronExpression, {
    onSuccess: (response, value) => {
      if (response) {
        console.log(response);
        queryClient.setQueryData(response);
      } else {
        console.log('پاسخی دریافت نشد');
      }
    }
  });
};

export { useCronExpression, useEditCronExpression };