import React from 'react';
import Badge from '../../../Components/Badge/Badge';
import BarChart from '../../../Components/Chart/BarChart';
import Wrapper from '../../../Wrapper/Wrapper';
import { useGetPeerLogList, useGetCountLog, useGetLastJobDone , useGetCountJobDone} from '../../../hooks/useReports';


const Dashboard = () => {

  const { data: peerLogList } = useGetPeerLogList();
  const {data: getCountLogList} = useGetCountLog()
  const {data: getLastJobDone} =useGetLastJobDone()
  const {data: getCountJobDone} = useGetCountJobDone()


  const logList = [
    {
      title: 'لاگ باقی مانده',
      value: getCountLogList?.item || 0
    },
    {
      title: 'کار انجام شده',
      value: getCountJobDone?.item || 0
    },
    {
      title: 'مدت زمان اشتراک',
      value: '12 ماه'
    },
    {
      title: 'آخرین کار ایجاد شده',
      value: getLastJobDone?.item || 'https://chekane.ir'
    },
  ];

  return (
    <Wrapper>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mx-auto my-10 w-11/12'>
        <BarChart value={peerLogList || []} />
        <BarChart value={peerLogList || []} />
      </div>
      <div className='grid grid-cols-2 md:grid-cols-4 gap-5 my-10 w-11/12 mx-auto'>
        {logList.map((item, index) => {
          return (
            <Badge key={index} item={item} />
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Dashboard;