import React from 'react';

const Tabs = ({ formUrl, changeHandler }) => {
  return (
    <div>
      <div className="mb-4 border-b border-gray-200 ">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
          <li className="mr-2" >
            <button className="inline-block p-4 rounded-t-lg border-b-2 text-blue-600 hover:text-blue-600  border-blue-600 " type="button">فراخوانی یک API</button>
          </li>
        </ul>
      </div>
      <div>
        <div className="p-4 rounded-lg h-96">
          <div className='grid grid-cols-1 md:grid-cols-2 gap-5 my-6 border-b '>
            <article className='md:mr-2'>
              <p className='text-base text-cyan-900 font-medium'>URL</p>
              <input name="address" value={formUrl?.address} onChange={changeHandler} className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2.5 w-full my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium' type="text" />
            </article>
            <article className='md:mr-2'>
              <p className='text-base text-cyan-900 font-medium'>Method</p>
              <select name="method" onChange={changeHandler} className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium'>
                <option value={0} >Get</option>
                <option value={1} >Post</option>
              </select>
            </article>
            <article className='md:mr-2'>
              <p className='text-base text-cyan-900 font-medium'>Header</p>
              <textarea name="header" value={formUrl?.header} onChange={changeHandler} rows='4' className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium'></textarea>
            </article>
            <article className='md:mr-2'>
              <p className='text-base text-cyan-900 font-medium'>Body</p>
              <textarea name="body" value={formUrl?.body} onChange={changeHandler} rows='4' className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium'></textarea>
            </article>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;