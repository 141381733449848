import CronWrapper from "../CronWrapper/CronWrapper";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

const CronDays = ({ toggleTab, changeHandlerCron, cronValue, setCronValue, }) => {


  const dayList = [
    {
      label: 'یکشنبه',
      id: 0,
    },
    {
      label: 'دوشنبه',
      id: 1,
    },
    {
      label: 'سه شنبه',
      id: 2,
    },
    {
      label: 'چهار شنبه',
      id: 3,
    }, {
      label: 'پنج شنبه',
      id: 4,
    }, {
      label: 'جمعه',
      id: 5,
    }, {
      label: 'شنبه',
      id: 6,
    },
  ];

  const calcedTime = () => {
    let d = new Date();
    let [hours, minutes] = cronValue.runTimeDisplay.split(':');
    d.setHours(+ hours);
    d.setMinutes(+ minutes);
    return d;
  };

  return (
    <CronWrapper onClick={
      () => toggleTab(99)
    }>
      <div>
        <p className="leading-normal text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0">
          <span className="text-xs text-purple-500 ml-2">4.</span>
          در روز معینی از هفته در ساعت معینی اجرا شود
        </p>
        <p className="text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0">
          <span className="text-xs text-purple-500 ml-2">مثال:
          </span>
          هر چهارشنبه در ساعت 2 تکرار شود
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 w-11/12 md:w-full mx-auto my-5">
        <div className="flex items-center justify-start w-full ">
          <p className="ml-4 text-xs sm:text-sm text-slate-700">چه ساعتی؟</p>
          <DatePicker disableDayPicker inputclassName="py-2 px-4 border outline-none rounded-lg bg-melo-white text-center text-slate-700 text-xs md:text-sm cursor-pointer hover:bg-zinc-100 w-full" format="HH:mm:ss"
            // eslint-disable-next-line react/jsx-key
            plugins={[<TimePicker />]}
            calendar={persian}
            locale={persian_fa}
            calendarPosition="bottom-right"
            name="runTimeDisplay"
            value={
              calcedTime()
            }
            onChange={
              (value) => {
                let d = value.toDate();
                let times = d.toTimeString().split(' ')[0].split(':');
                let time = `${times[0]
                  }:${times[1]
                  }`;
                setCronValue((inputValue) => ({
                  ...inputValue,
                  runTimeDisplay: time,
                }));
              }
            } />
        </div>
        <div className="flex items-center justify-start w-full ">
          <p className="ml-4 text-xs sm:text-sm text-slate-700">چه روزی؟</p>
          <select name="daysToRunDisplay"
            value={
              cronValue.daysToRunDisplay
            }
            onChange={changeHandlerCron}
            className="py-1 px-4 border outline-none rounded-lg bg-melo-white text-center text-slate-700 text-xs md:text-sm cursor-pointer hover:bg-zinc-100 w-6/12">
            <option>چه روزی...</option>
            {
              dayList.map((item) => {
                const { label, id, } = item;
                return (
                  <option value={id}
                    key={id}>
                    {label} </option>
                );
              })
            } </select>
        </div>
      </div>
    </CronWrapper>
  );
};

export default CronDays;
