import React from 'react';
import devTeam from '../../../assets/Images/devTeam.png';

const Header = () => {
  return (
    <div id="home" className='h-3/5 w-11/12 mx-auto'>
      <div className='grid grid-cols-1 sm:grid-cols-2 place-items-center w-11/12  mx-auto'>
        <div className=' order-2 sm:order-1 grid grid-cols-1 grid-rows-3 lg:gap-6 place-items-start md:w-9/12'>
          <p className='font-medium text-sm md:text-base lg:text-lg text-slate-700 leading-8'>چکانه ابزار ساده و قدرتمندیست که کارهای شما را زمانبندی و اجرا می کند</p>
          <p className='font-medium text-sm md:text-base lg:text-lg text-slate-700 leading-8'>با استفاده از چکانه قادر خواهید بود تا Api های خود را در زمان های مشخص اجرا و تاریخچه ی اجرای آن را مشاهده کنید</p>
          <button className='text-sm font-medium text-slate-700 border px-4 py-2 rounded mt-12 shadow-btn'>شروع کار با چکانه</button>
        </div>
        <img src={devTeam} alt="development Team" className='order-1 sm:order-2 w-full md:w-11/12 h-full object-contain mr-auto' />
      </div>
    </div>
  );
};

export default Header;