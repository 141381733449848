import React from 'react';
import JsonFormatter from 'react-json-formatter';

const Modal = ({ data, setShowModal }) => {

  console.log(data.jobName);

  const jsonStyle = {
    propertyStyle: { color: '#E94560' },
    stringStyle: { color: '#42855B' },
    numberStyle: { color: '#554994' }
  };

  return (
    <div tabindex="-1" className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center " style={{ height: '100vh' }}>
      <div className="relative p-4 w-full max-w-3xl h-full md:h-auto">
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow ">
          {/* <!-- Modal header --> */}
          <div className="flex justify-between items-center w-full p-2 rounded-t border-b ">
            <h3 className="text-base font-medium text-cyan-700 ">
              جزئیات لاگ
            </h3>
            <button onClick={() => setShowModal(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 mr-auto inline-flex items-center " data-modal-toggle="defaultModal">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          {/* <!-- Modal body --> */}
          <div>
            <p className="text-sm text-cyan-600 font-medium m-2">کار:
              <span className='text-slate-600 mr-4'>{data?.jobName}</span></p>
            <p className="text-sm text-cyan-600 font-medium m-2">تاریخ اجرا:
              <span className='text-slate-600 mr-4'>{data?.dateDispaly}</span></p>
            <p className="text-sm text-cyan-600 font-medium m-2">ساعت اجرا:
              <span className='text-slate-600 mr-4'>{data?.timeDisplay}</span></p>
            <p className="text-sm text-cyan-600 font-medium m-2">وضعیت:
              <span className='text-slate-600 mr-4'>{data?.statusCodeDisplay}</span></p>
            <p className="text-sm text-cyan-600 font-medium m-2">نتیجه:
            </p>
          </div>
          <div className="p-6 border h-52 w-11/12 rounded overflow-y-auto mx-auto my-6">

            <JsonFormatter json={JSON.stringify(data.result)} tabWith={4} jsonStyle={jsonStyle} />



          </div>
          {/* <!-- Modal footer --> */}
          <div className="flex items-center p-2 space-x-1 rounded-b border-t border-gray-200">
            <button onClick={() => setShowModal(false)} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-1.5 hover:text-gray-900 focus:z-10">بستن</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;