import React, { useEffect, useState } from 'react';
import Wrapper from '../../../Wrapper/Wrapper';
import TableUi from '../../../Components/TableUi/TableUi';
import { useGetHistoryLog } from '../../../hooks/useHistoryLog';
import { useLocation } from 'react-router-dom';
import Modal from '../../../Components/Modal/Modal';
import Fixing from '../../../assets/Images/fixing.png';
import Loading from '../../../Components/Loading/Loading';

const History = () => {
  const location = useLocation();
  const [logId, setLogId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [resultData, setResultData] = useState([]);
  const [current, setCurrent] = useState(1);

  const itemNumber = (current - 1) * 7 + 1;

  useEffect(() => {
    setLogId(location?.search.split('=')?.[1]);
  }, [location.pathname]);


  const { data: getHistoryLog, isLoading: logLoading } = useGetHistoryLog({
    page: current,
    take: 7,
    cjobid: logId
  });

  const resultModal = (id) => {
    setShowModal(!showModal);
    setResultData(id);
  };

  const column = [
    { path: 'jobName', name: 'کار' },
    { path: 'dateDispaly', name: 'تاریخ اجر' },
    { path: 'timeDisplay', name: 'زمان اجرا' },
    { path: 'statusCodeDisplay', name: 'وضعیت' },
    { path: 'result', name: 'نتیجه', onClick: resultModal }
  ];




  return (
    <Wrapper>
      {showModal && <Modal setShowModal={setShowModal} data={resultData} />}
      <div className='h-screen bg-white border rounded-sm p-2 mx-auto my-10 w-11/12'>
        <div className='px-6 py-1.5 border rounded-sm text-sm font-medium shadow-md hover:bg-light active:shadow-sm text-cyan-600 w-fit' >تاریخچه</div>
        {logLoading ? <Loading /> :
          <>
            {
              getHistoryLog.length < 1 ?
                <div>
                  < p className='flex items-center justify-center py-2 px-6 rounded border w-fit mt-6 text-slate-600 text-sm font-medium'>هنوز لاگی ثبت نشده است</p>
                  <img src={Fixing} alt="fixing work" className="w=11/12 sm:w-8/12 lg:w-6/12 object-contain mx-auto h-80" />
                </div>
                :
                <TableUi itemNumber={itemNumber} current={current} setCurrent={setCurrent} pages={20} column={column} data={getHistoryLog} showBtns={false} />
            }
          </>
        }
      </div >
    </Wrapper >
  );
};

export default History

