const CronWrapper = ({ children , onClick}) => {
  return (
    <div className="w-full min-h-fit h-auto p-3 relative">
      {children}
      <div className="">
        <button className=" border rounded-lg p-2.5 text-xs md:text-sm text-slate-600 bg-zinc-100 active:scale-95 hover:bg-zinc-200 outline-none mt-5" onClick={onClick}>بازگشت</button>
      </div>
    </div>
  );
};

export default CronWrapper;
