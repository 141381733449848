import * as api from '../apis/HistoryLog';
import { useQuery } from 'react-query';




const useGetHistoryLog = (activePage) => {
  return useQuery(['historyLogList', activePage], () => api.getHistoryLog(activePage),
   { keepPreviousData: true });
};

export { useGetHistoryLog };