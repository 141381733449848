import React from 'react';

const Error404 = () => {
  return (
    <div className='flex items-center justify-center text-center h-screen w-full m-auto'>
      <p className='text-slate-600 text-base font-medium m-auto'>صفحه ای با این آدرس یافت نشد</p>
    </div>
  );
};

export default Error404;