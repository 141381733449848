import * as api from '../apis/Job';
import { useMutation, useQuery } from 'react-query';
import { queryClient } from '../index';
import toast from 'react-hot-toast';


const useGetJobList = (values) => {
  return useQuery(['jobList', values], () => api.getJobList(values), { keepPreviousData: true });
};

const useGetJobListWithId = (values) => {
  // return useQuery('jobDataWithId', () => api.getJobListWidthId(values));
  return useMutation(api.getJobListWidthId, {
    onSuccess: (response) => {
      if (response) {
        queryClient.setQueryData('jobListWithId')
      } else {
        console.log(response.message || 'پاسخی از سمت سرور دریافت نشد');
      }
    }
  });
};

const useDeleteJob = (value) => {
  return useMutation(api.deleteJob, {
    onSuccess: (response, cJobId) => {
      if (response) {
        const lastData = queryClient.getQueryData('jobList');
        const newData = lastData.filter(item => item.cJobId !== cJobId);
        queryClient.setQueryData('jobList', newData);
        toast.success('آیتم مورد نظر با موفقیت حذف شد');
      } else {
        console.log(response?.message);
      }
    }
  },
  );
};

export { useGetJobList, useDeleteJob, useGetJobListWithId };