import * as api from '../apis/Setting';
import { useQuery, useMutation } from 'react-query';
import { queryClient } from '../index';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


const useGetDataUser = (value) => {
  return useQuery('userData', () => api.getEditUserData(value));
};

const useChangeUserData = (value) => {
  const navigate = useNavigate();
  return useMutation(api.postEditUserData, {
    onSuccess: (response, cUserId) => {
      if (response) {
        queryClient.setQueryData('userData', response);
        toast.success('تنظیمات با موفقیت ذخیره شد');
        navigate('/dashboard');
      } else {
        console.log(response.massage || 'داده ای یافت نشد');
      }
    }
  });
};

export { useGetDataUser, useChangeUserData };