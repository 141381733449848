import React from 'react';

const PlansCard = ({ item, className}) => {

  const icon = <svg xmlns="http://www.w3.org/2000/svg" 
  fill="none" 
  viewBox="0 0 24 24" 
  strokeWidth={1.5} 
  stroke="currentColor" 
  className="w-4 h-4 ml-2"
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
  </svg>
  return (
    <div className='flex items-center justify-start flex-col bg-white border rounded-lg p-2 h-96 w-9/12 mx-auto cursor-pointer hover:shadow-md duration-200'>
      <p className='mt-2 font-semibold text-base text-slate-700 flex items-center justify-center'>
        {item.title}</p>
      <div className='my-auto'>
        <p className='text-xs text-stone-600 font-medium my-8 flex items-center justify-start' >
          {icon}
          {item.item1}</p>
        <p className='text-xs text-stone-600 font-medium my-8 flex items-center justify-start' >
          {icon}
          {item.item2}</p>
        <p className='text-xs text-stone-600 font-medium my-8 flex items-center justify-start' >
          {icon}
          {item.item3}</p>
        <p className='text-xs text-stone-600 font-medium my-8 flex items-center justify-start' >
          {icon}
          {item.item4}</p>
      </div>
      <button className={`${className} text-sm font-medium border border-red-300 px-4 py-2 rounded my-2 w-10/12 `}>انتخاب پلن</button>
    </div>
  );
};

export default PlansCard;