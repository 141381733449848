import React from 'react';
import './Loading.css'

const Loading = () => {
  return (
    <div>
      <div id="cssLoader17" class="main-wrap main-wrap--white">
        <div class="cssLoader17"></div>
      </div>
      <div id="cssLoader1" class="main-wrap">
        <div class="child-common child1"></div>
        <div class="child-common child2"></div>
        <div class="child-common child3"></div>
        <div class="child-common child4"></div>
        <div class="child-common child5"></div>
        <div class="child-common child6"></div>
        <div class="child-common child7"></div>
        <div class="child-common child8"></div>
        <div class="child-common child9"></div>
        <div class="child-common child10"></div>
      </div>
    </div>
  );
};

export default Loading;