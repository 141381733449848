import React, { useEffect, useState } from 'react';
import TableUi from '../../../Components/TableUi/TableUi';
import Wrapper from '../../../Wrapper/Wrapper';
import { useGetJobList } from '../../../hooks/useJob';
import { useDeleteJob } from '../../../hooks/useJob';
import Fixing from '../../../assets/Images/fixing.png';
import Loading from '../../../Components/Loading/Loading';

const Works = () => {

  const [current, setCurrent] = useState(1);

  const itemNumber = (current - 1) * 7 + 1;

  const { mutate: deleteMutate, isLoading: deleteLoading } = useDeleteJob();
  const { data: jobList, isLoading: jobListLoading } = useGetJobList({
    page: current,
    take: 15
  });
  const column = [
    { path: 'name', name: 'نام کار' },
    { path: 'address', name: 'آدرس' },
    { path: 'cronDescription', name: 'زمان اجرا' },
    { path: 'description', name: 'توضیحات' },
  ];


  return (
    <Wrapper>

      <div className='h-screen bg-white border rounded-sm p-2 mx-auto my-10 w-11/12'>
        <button className='px-6 py-1.5 border rounded-sm text-sm font-medium shadow-md hover:bg-light active:shadow-sm text-cyan-600' >کار ها</button>
        {jobListLoading ? <Loading /> :
          <>
            {jobList?.length < 1 ?
              <div>
                <p className='flex items-center justify-center py-2 px-6 rounded border w-fit mt-6 text-slate-600 text-sm font-medium'>هنوز کاری انجام نگرفته است</p>
                <img src={Fixing} alt="fixing work" className="w=11/12 sm:w-8/12 lg:w-6/12 object-contain mx-auto h-80" />
              </div>
              :
              <TableUi
                itemNumber={itemNumber}
                current={current} setCurrent={setCurrent} pages={20}
                deleteBtn={deleteMutate}
                column={column} data={jobList?.map(item => item)}
              />
            }
          </>
        }
      </div>
    </Wrapper>
  );
};

export default Works;