import React from 'react';
import { Link } from 'react-router-dom';
import Captcha from '../DrCaptcha/Captcha';

const LoginComp = ({ phoneNumber, setPhoneNumber, registerHandler, register, setRegister, showPassword, loginValue, setLoginValue, loginHandler }) => {
  return (
    <form className="space-y-6" >
      <div>
        <p className="block mb-2 text-sm font-medium text-gray-900 ">شماره موبایل</p>
        <input name="phoneNumber" value={phoneNumber}
          onChange={(e) => {
            if (e.target.value.length < 12) {
              setPhoneNumber(e.target.value);
            }
          }}
          type="number" className="login-input"
        />
      </div>
      {/* {!showPassword && <Captcha />} */}
      {showPassword &&
        <div>
          <p className="block mb-2 text-sm font-medium text-gray-900 ">رمز عبور</p>
          <input name="passWord" value={loginValue.passWord}
            onChange={(e) => setLoginValue({ ...loginValue, passWord: e.target.value })}
            type="password" className="login-input"
            placeholder="*** ***" />
        </div>
      }
      {showPassword ?
        <button onClick={loginHandler} type="submit" className="login-btn">
          ورود
        </button>
        :
        <button onClick={registerHandler} type="submit" className="login-btn">
          ارسال
        </button>
      }
      <div className="text-sm font-medium text-gray-500 ">
        <Link onClick={() => setRegister(!register)} to="#" className="text-blue-700 hover:underline py-0.5 flex items-center justify-start">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>
          {!register ? 'ثبت نام' : 'ورود'}
        </Link>
      </div>
    </form>
  );
};

export default LoginComp;