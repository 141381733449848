import { useEffect } from "react";
import CronContainer from './CronContainer/CronContainer';
import CronResult from "./CronResult/CronResult";

// ---------------- Cron Components
import CronDay from "./CronDay/CronDay";
import CronDays from "./CronDays/CronDays";
import CronHour from "./CronHour/CronHour";
import CronIdeal from "./CronIdeal/CronIdeal";
import CronManyDays from "./CronManyDays/CronManyDays";
import CronMinutes from "./CronMinutes/CronMinutes";
import CronMonth from "./CronMonth/CronMonth";
import CronWeekDays from "./CronWeekDays/CronWeekDays";
import CronYear from "./CronYear/CronYear";

// --------------------

const CronExpression = ({ cronValue, setCronValue, cronCode, setCronCode, formUrl, setFormUrl, sendCron, getCron }) => {


  const toggleTab = (index) => {
    setCronValue((cron) => {
      return {
        ...cron,
        timeType: index,
        daysToRunDisplay: [],
      };
    });
  };


  const changeHandlerCron = (e) => {
    if (e.target.name === "daysToRunDisplay") {
      if (e.target.type === "select-one") {
        setCronValue((prevInputChange) => {
          return {
            ...prevInputChange,
            [e.target.name]: [+ e.target.value],
          };
        });
      }
      else if (e.target.type === "radio") {
        setCronValue((prevInputChange) => {
          return {
            ...prevInputChange,
            [e.target.name]: [+ e.target.value],
          };
        });
      }
      else {
        if (e.target.checked) {
          setCronValue((prevInputChange) => {
            return {
              ...prevInputChange,
              [e.target.name]: [
                ...cronValue.daysToRunDisplay, + e.target.value,
              ],
            };
          });
        }
        else {
          let newArr = cronValue.daysToRunDisplay.filter((item) => item !== parseInt(e.target.value));
          setCronValue((prevInputChange) => {
            return {
              ...prevInputChange,
              [e.target.name]: newArr,
            };
          });
        }
      }
    }
    else if (e.target.name === "dayOfTheMonthToRunOn") {
      setCronValue((prevInputChange) => {
        return {
          ...prevInputChange,
          [e.target.name]: + e.target.value,
        };
      });
    }
    else if (e.target.name === "monthsToRunOn") {
      setCronValue((prevInputChange) => {
        return {
          ...prevInputChange,
          [e.target.name]: + e.target.value,
        };
      });
    }
    else if (e.target.name === "timeOfMonthToRun") {
      setCronValue((prevInputChange) => {
        return {
          ...prevInputChange,
          [e.target.name]: + e.target.value,
        };
      });
    }
    else if (e.target.name === "amountOfMonthsToRunAfter") {
      setCronValue((prevInputChange) => {
        return {
          ...prevInputChange,
          [e.target.name]: + e.target.value,
        };
      });
    }
    else if (e.target.name === "hours") {
      setCronValue((prevInputChange) => {
        return {
          ...prevInputChange,
          [e.target.name]: + e.target.value,
        };
      });
    }
    else if (e.target.name === "minutes") {
      setCronValue((prevInputChange) => {
        return {
          ...prevInputChange,
          [e.target.name]: + e.target.value,
        };
      });
    }
    else {
      setCronValue((prevInputChange) => {
        return {
          ...prevInputChange,
          [e.target.name]: e.target.value,
        };
      });
    }
  };


  const fetchCronCode = () => {
    sendCron(cronValue);

  };

  useEffect(() => {
    if (cronValue.timeType !== 99) {
      fetchCronCode();
    }
  }, [cronValue]);

  useEffect(() => {
    if (getCron) {
      setCronCode({ cronExpresion: getCron?.cronExpression, cronDescription: getCron?.cronDescription, });
      setFormUrl({ ...formUrl, cronExpresion: getCron?.cronExpresion });
    }
  }, [getCron]);


  const showCronContainer = () => {
    switch (cronValue.timeType) {
      case 99:
        return <CronContainer toggleTab={toggleTab} />;
      case 0:
        return <CronMinutes changeHandlerCron={changeHandlerCron}
          setCronValue={setCronValue}
          cronValue={cronValue}
          toggleTab={toggleTab} />;
      case 1:
        return <CronHour changeHandlerCron={changeHandlerCron}
          setCronValue={setCronValue}
          cronValue={cronValue}
          toggleTab={toggleTab} />;
      case 2:
        return <CronWeekDays changeHandlerCron={changeHandlerCron}
          cronValue={cronValue}
          setCronValue={setCronValue}
          toggleTab={toggleTab} />;
      case 3:
        return <CronDays changeHandlerCron={changeHandlerCron}
          setCronValue={setCronValue}
          cronValue={cronValue}
          toggleTab={toggleTab} />;
      case 4:
        return <CronDay changeHandlerCron={changeHandlerCron}
          setCronValue={setCronValue}
          cronValue={cronValue}
          toggleTab={toggleTab} />;
      case 5:
        return <CronManyDays changeHandlerCron={changeHandlerCron}
          setCronValue={setCronValue}
          cronValue={cronValue}
          toggleTab={toggleTab} />;
      case 6:
        return <CronIdeal changeHandlerCron={changeHandlerCron}
          setCronValue={setCronValue}
          cronValue={cronValue}
          toggleTab={toggleTab} />;
      case 7:
        return <CronMonth changeHandlerCron={changeHandlerCron}
          setCronValue={setCronValue}
          cronValue={cronValue}
          toggleTab={toggleTab} />;
      case 8:
        return <CronYear changeHandlerCron={changeHandlerCron}
          setCronValue={setCronValue}
          cronValue={cronValue}
          toggleTab={toggleTab} />;

      default:
        return <CronContainer toggleTab={toggleTab} />;
    }
  };

  return (
    <div className="bg-white  w-full h-fit">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full h-fit ml-auto md:mx-auto mt-2 px-2">
        <CronResult title="Cron Code:"
          result={
            cronCode.cronExpresion
          } />
        <CronResult title="زمان اجرا:"
          result={
            cronCode.cronDescription
          } />
      </div>
      {
        showCronContainer()
      } </div>
  );
};

export default CronExpression;
