import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// ------------- React Query
import { QueryClient, QueryClientProvider } from 'react-query';
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);


