import client from '../utils/AxiosInterceptor';


const getEditUserData = async (value) => {
  const { data } = await client.get('/Setting/EditUser', value);
  return data;
};

const postEditUserData = async (value) => {
  const { data } = await client.post('/Setting/EditUser', value);
  return data;
};

export { getEditUserData, postEditUserData };