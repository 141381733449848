import CronWrapper from "../CronWrapper/CronWrapper";
import { useState } from "react";

const CronMinutes = ({ toggleTab, changeHandlerCron, cronValue }) => {


  const minit = 1;
  const minutesOption = [];
  for (let minutes = minit; minutes <= 59; minutes++) {
    minutesOption.push(<option value={minutes}>{minutes}</option>);
  }

  return (
    <CronWrapper onClick={() => toggleTab(99)}>
      <div>
        <p className="text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0 lg:w-4/12">
          <span className="text-xs text-purple-500 ml-2">1.</span>بر اساس دقیقه
          اجرا شود
        </p>
        <p className="text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0 lg:w-4/12">
          <span className="text-xs text-purple-500 ml-2">مثال: </span>هر پنج
          دقیقه یک بار انجام شود
        </p>
      </div>
      <div className="flex items-center justify-start ml-auto">
        <select
          name="minutes"
          onChange={changeHandlerCron}
          value={cronValue.minutes}
          className="w-44 py-1 px-2 rounded-lg bg-melo-white border outline-none text-center texxt-slate-700 "
        >
          {minutesOption}
        </select>
        <p className="mr-3 text-xs md:text-sm text-slate-700">دقیقه</p>
      </div>
    </CronWrapper>
  );
};

export default CronMinutes;
