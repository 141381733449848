import React from 'react';
import Wrapper from '../../../Wrapper/Wrapper';
import Creditcard from '../../../assets/Images/Creditcard.png';

const PaymentConfirmation = () => {
  return (
    <Wrapper>
      <div className='h-screen bg-white border rounded-sm p-2 mx-auto my-10 w-11/12'>
        <div className='px-6 py-1.5 border rounded-sm text-sm font-medium shadow-md hover:bg-light active:shadow-sm text-cyan-600 w-fit' >تایید پرداخت</div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 w-11/12 mx-auto mt-10'>
          <div className='md:mt-12 flex items-center justify-start flex-col'>
            <p className='text-slate-600 font-medium text-xs md:text-sm w-11/12 mx-auto' style={{lineHeight: '2.25rem'}} >با تشکر از پرداخت شما، لطفا برای فعال سازی پلن رسید خود را بصورت عکس آپلود کنید</p>
           <div className='w-11/12'>
           <input type="file" id="upload" hidden/>
            <label for="upload">Choose file</label>
           </div>
          </div>
          <div>
            <img src={Creditcard} alt="Credit Card Vector" className='w-10/12 object-contain' />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default PaymentConfirmation;