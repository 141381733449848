import React, { useEffect } from "react";

// const response = document.getElementById("dc-textarea-response").value = 'sina'
// alert(response);

function Captcha() {
  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.innerHTML = `
    !function(){
    function c(){
    var a=document.createElement("script");
    a.type="text/javascript",
    a.async=!0,
    a.src="https://DrCaptcha.ir/api/js?token="+f;
    var g=document.getElementsByTagName("script")[0];
    g.parentNode.insertBefore(a,g)}
    var d=document,b=window,
    f="ttEVeVCpUMzLbqj/BvlwPiHKaWN8xq8i3uR9jM7fDDGr/IuZi5Mf3nqQ4/U/zBQ8Djg8zbmrUVfig7BP40pA==";
    "complete"==d.readyState?c():b.attachEvent?b.attachEvent("onload",c):b.addEventListener("load",c,!1)}();`;
    // scriptTag.addEventListener('load',()=>setLoaded(true));
    document.body.appendChild(scriptTag);
  }, []);

  return (
    <div className="dc-captcha set-ele-middle">
      <div
        style={{
          paddingTop: "30px",
          height: "100px",
          width: "300px",
          font: "normal 15px ir_sans,tahoma",
          lineHeight: "40px",
          color: "#000",
          textAlign: "center",
          borderRadius: "5px",
          boxShadow: "inset 0 1px 2px rgba(0,0,0,.1)",
          WebkitBoxShadow: "inset 0 -1px 0 rgba(0,0,0,.15)",
          animation: "progress-bar-stripes 2s linear infinite",
          webkitTrWebkitTransitionansition: "width .6s ease",
          oTransition: "width .6s ease",
          transition: "width .6s ease",
          backgroundColor: "#1abc9c",
          backgroundImage:
            "linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)",
          Webkitbackgroundsize: "40px 40px",
          backgroundsize: "40px 40px",
        }}
      >
        لطفا کمی صبر کنید تا کپچا بارگذاری شود
      </div>
    </div>
  );
}

export default Captcha;

// document.getElementById("dc-textarea-response").value
