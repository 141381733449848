import CronWrapper from "../CronWrapper/CronWrapper";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { dayList } from "../CronData";

const CronManyDays = ({ toggleTab, changeHandlerCron, cronValue, setCronValue }) => {
  // const weekDays = [
  //   "شنبه",
  //   "یکشنبه",
  //   "دوشنبه",
  //   "سه شنبه",
  //   "چهارشنبه",
  //   "پنج شنبه",
  //   "جمعه",
  // ];


  const calcedTime = () => {
    let d = new Date();
    let [hours, minutes] = cronValue.runTimeDisplay.split(':');
    d.setHours(+ hours);
    d.setMinutes(+ minutes);
    return d;
  };

  return (
    <CronWrapper onClick={
      () => toggleTab(99)
    }>
      <div>
        <p className="leading-normal text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0">
          <span className="text-xs text-purple-500 ml-2">6.</span>
          در زمان معین شده چندین بار در هفته اجرا شود
        </p>
        <p className="text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0">
          <span className="text-xs text-purple-500 ml-2">مثال:
          </span>
          از شنبه تا چهارشنبه ساعت 11 اجرا شود
        </p>
      </div>
      {/* ---------------  */}
      <p className="text-xs md:text-sm text-slate-600">چه روز هایی از هفته؟</p>
      <fieldset className="grid grid-cols-2 md:grid-cols-4 gap-5 w-full my-3 mx-auto">
        {
          dayList.map((item) => {
            const { label, id } = item;
            return (
              <div key={id}
                className="flex items-center mb-4">
                <input name="daysToRunDisplay"
                  value={id}
                  onChange={changeHandlerCron}
                  id={id}
                  aria-describedby="checkbox-2"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded  border-gray-300 outline-none " />
                <p htmlFor={id}
                  className="mr-3  text-sm font-medium text-slate-600 ">
                  {label} </p>
              </div>
            );
          })
        } </fieldset>

      {/* -------------- */}
      <div className="flex items-center justify-start ml-auto">
        <p className="text-slate-700 text-xs sm:text-sm ml-2">چه ساعتی؟</p>
        <DatePicker disableDayPicker inputclassName="py-2 px-4 border outline-none rounded-lg bg-melo-white text-center text-slate-700 text-xs md:text-sm cursor-pointer hover:bg-zinc-100" format="HH:mm:ss"
          // eslint-disable-next-line react/jsx-key
          plugins={[<TimePicker />]}
          calendar={persian}
          locale={persian_fa}
          calendarPosition="bottom-right"
          name="runTimeDisplay"
          value={calcedTime()}
          onChange={(value) => {
            let d = value.toDate();
            let times = d.toTimeString().split(' ')[0].split(':');
            let time = `${times[0]
              }:${times[1]
              }`;
            setCronValue((inputValue) => ({
              ...inputValue,
              runTimeDisplay: time,
            }));
          }}
        />
      </div>
    </CronWrapper>
  );
};

export default CronManyDays;
