import CronWrapper from "../CronWrapper/CronWrapper";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { days, monthList, dayList, } from '../CronData';

const CronMonth = ({ toggleTab, changeHandlerCron, cronValue, setCronValue, }) => {


  const calcedTime = () => {
    let d = new Date();
    let [hours, minutes] = cronValue.runTimeDisplay.split(':');
    d.setHours(+ hours);
    d.setMinutes(+ minutes);
    return d;
  };

  return (
    <CronWrapper onClick={
      () => toggleTab(99)
    }>
      <div>
        <p className="leading-normal text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0">
          <span className="text-xs text-purple-500 ml-2">8.</span>
          هر چند ماه یکبار در چندمین هفته اجرا شود
        </p>
        <p className="text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0">
          <span className="text-xs text-purple-500 ml-2">مثال:
          </span>
          در دومین روز از ماه در ساعت معین تکرار شود
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mx-auto">
        <div className="flex items-center justify-start">
          <p className="text-slate-700 text-xs sm:text-sm ml-2">چه ساعتی؟</p>
          <DatePicker disableDayPicker inputclassName="py-2 px-4 border outline-none rounded-lg bg-melo-white text-center text-slate-700 text-xs md:text-sm cursor-pointer hover:bg-zinc-100" format="HH:mm:ss"
            // eslint-disable-next-line react/jsx-key
            plugins={[<TimePicker />]}
            calendar={persian}
            locale={persian_fa}
            calendarPosition="bottom-right"
            name="runTimeDisplay"
            value={
              calcedTime()
            }
            onChange={
              (value) => {
                let d = value.toDate();
                let times = d.toTimeString().split(' ')[0].split(':');
                let time = `${times[0]
                  }:${times[1]
                  }`;
                setCronValue((inputValue) => ({
                  ...inputValue,
                  runTimeDisplay: time,
                }));
              }
            } />
        </div>
        <div className="flex items-center justify-start ">
          <p className="text-slate-700 text-xs sm:text-sm ml-2">
            چه روزی از ماه؟
          </p>
          <select name="timeOfMonthToRun"
            value={
              cronValue.timeOfMonthToRun
            }
            onChange={changeHandlerCron}
            className="w-44 py-1 px-2 rounded-lg bg-melo-white border outline-none text-center text-slate-600 text-xs md:text-sm">
            {
              days.map((item, index) => {
                return (
                  <option key={index}
                    value={index + 1}>
                    {item} </option>
                );
              })
            } </select>
        </div>
      </div>
      <div className="my-5 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-5 w-full">
        <p className="text-xs md:text-sm text-slate-700">چه روزی از هفته؟</p>
        {
          dayList.map((item) => {
            const { label, id } = item;
            return (
              <div key={id}
                className="flex items-center mb-4">
                <input id={id}
                  type="radio"
                  name="daysToRunDisplay"
                  value={id}
                  onChange={changeHandlerCron}
                  className="w-4 h-4 outline-none ml-2"
                  aria-labelledby={id}
                  aria-describedby={id} />
                <p htmlFor={id}
                  className="block ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {label} </p>
              </div>
            );
          })
        } </div>
      <div className="flex items-center justify-start ">
        <p className="text-slate-700 text-xs sm:text-sm ml-2">هر چند ماه؟
        </p>
        <select name="amountOfMonthsToRunAfter"
          value={
            cronValue.amountOfMonthsToRunAfter
          }
          onChange={changeHandlerCron}
          className="w-44 py-1 px-2 rounded-lg bg-melo-white border outline-none text-center texxt-slate-600 text-xs md:text-sm">
          {
            monthList.map((item, index) => {
              return (
                <option key={index}
                  value={index + 1}>
                  {item} </option>
              );
            })
          } </select>
      </div>
    </CronWrapper>
  );
};

export default CronMonth;
