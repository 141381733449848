import React, { useState } from 'react';
import Wrapper from '../../../Wrapper/Wrapper';
import Mahak from '../../../assets/Images/mahak.png';
import Kahrizak from '../../../assets/Images/kahrizak.png';
import { useLocation, useNavigate } from 'react-router-dom';

const Pay = () => {

  const [selectItem, setSelectItem] = useState(1);
  const navigate = useNavigate();


  const charityHandler = () => {
    if (selectItem === 1) {
      window.open('https://mahak-charity.org/online-payment/',  '_blank');
    } else {
      window.open('https://kahrizakcharity.com/donation-of-property/how-to-proceed',  '_blank');
    }
  };

  return (
    <Wrapper>
      <div className='h-fit bg-white border rounded-sm p-2 mx-auto my-10 w-11/12'>
        <div className='px-6 py-1.5 border rounded-sm text-sm font-medium shadow-md hover:bg-light active:shadow-sm text-cyan-600 w-fit' >
          صفحه ی پرداخت
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-3 place-items-center mx-auto w-11/12 mt-10'>
          <button onClick={() => setSelectItem(1)} className={`${selectItem === 1 ? 'border-cyan-700' : 'border-white'} w-full md:w-8/12 rounded border-2 shadow-md p-2 text-center`}>
            <img src={Mahak} alt="Mahak_image" className='h-40 w-full object-contain' />
            <p className="text-slate-700 font-medium text-sm my-3">حمایت از کودکان مبتلا به سرطان</p>
            <p className="text-cyan-700 font-semibold text-sm my-3">موسسه خیریه مهک</p>
          </button>
          <button onClick={() => setSelectItem(2)} className={`${selectItem === 2 ? 'border-cyan-700' : 'border-white'} w-full md:w-8/12 rounded border-2 shadow-md p-2 text-center`}>
            <img src={Kahrizak} alt="Kahrizak_image" className='h-40 w-full object-contain' />
            <p className="text-slate-700 font-medium text-sm my-3">درمان و آموزش معلولین و سالمندان</p>
            <p className="text-cyan-700 font-semibold text-sm my-3">موسسه خیریه کهریزک</p>
          </button>
        </div>
        <div className="flex items-center justify-center mx-auto w-11/12">
          <button onClick={charityHandler} className='text-sm font-medium text-white bg-cyan-700 hover:bg-cyan-600 py-1 px-6 border mt-8 rounded mx-auto'>ادامه</button>
        </div>
      </div>
    </Wrapper>

  );
};

export default Pay;