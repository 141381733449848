import React from 'react';
import PlansCard from './PlansCard';

const Plans = () => {

  const plansList = [
    {
      title: 'رایگان',
      item1: 'تعداد سه کار حداکثر',
      item2: 'تعداد 100 لاگ در ماه به ازای هرکار',
      item3: 'استفاده تمام از قابلیت های فقط روزانه و هفتگی',
      item4: 'مبلغ پرداختی پلن رایگان',
    },
    {
      title: 'ویژه',
      item1: 'تعداد 10 کار حداکثر',
      item2: 'تعداد 1000 کار در ماه به ازای هر ماه',
      item3: 'استفاده از تمام قابلیت ها در بازه های زمانی',
      item4: ' قیمت پلن 20,000 هزار تومان',
    },
    {
      title: 'سازمانی',
      item1: 'تعداد نامحدود کار در هر ماه',
      item2: 'تعداد نامحدود کار به ازای هر ماه',
      item3: 'استفاده از تمام قابلیت ها در بازه های زمانی',
      item4: 'قیمت پلن 50،000 هزار تومان',
    },

  ];

  return (
    <div id='plans' className='py-20' style={{ background: '#fff7ed' }}>
      <div className='w-11/12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 mx-auto'>
        {plansList?.map((item, index) => {
          return (
            <PlansCard className={index === 1 ? 'bg-red-500 text-white': 'bg-white text-slate-700'} key={index} item={item} />
          );
        })}
      </div>
    </div>
  );
};

export default Plans;