import CronWrapper from "../CronWrapper/CronWrapper";

const CronHour = ({ toggleTab, changeHandlerCron, cronValue }) => {
  const hour = 1;
  const hourList = [];
  for (let hours = hour; hours <= 23; hours++) {
    hourList.push(<option value={hours}>{hours}</option>);
  }

  return (
    <CronWrapper onClick={() => toggleTab(99)}>
      <div>
        <p className="text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0 lg:w-4/12">
          <span className="text-xs text-purple-500 ml-2">2.</span>
          بر اساس ساعت اجرا شود
        </p>
        <p className="text-xs md:text-sm text-slate-700 border p-2.5 rounded-lg w-11/12 text-center mx-auto mb-3 md:w-6/12 md:text-right md:ml-auto md:mr-0 lg:w-4/12">
          <span className="text-xs text-purple-500 ml-2">مثال: </span>
          هر دو ساعت یکبار انجام شود
        </p>
      </div>
      <div className="flex items-center justify-start ml-auto">
        <select
          name="hours"
          onChange={changeHandlerCron}
          value={cronValue.hours}
          className="w-44 py-1 px-2 rounded-lg bg-melo-white border outline-none text-center texxt-slate-700 "
        >
          {hourList}
        </select>
        <p className="mr-3 text-xs md:text-sm text-slate-700">ساعت</p>
      </div>
    </CronWrapper>
  );
};

export default CronHour;
