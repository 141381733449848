import React from 'react';
import {
  FrontPage,
  Dashboard,
  Works,
  CreateWork,
  History,
  UpgradeAccount,
  PaymentConfirmation,
  Setting,
  Pay,
  Error404
} from './Pages';
import ProtectedRoute from './routes/ProtectedRoute';

import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';


const App = () => {
  return (
    <BrowserRouter>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Routes>
        <Route path={'/'} element={<FrontPage />} />
        <Route element={<ProtectedRoute />} >
          <Route path={'/dashboard'} element={<Dashboard />} />
          <Route path={'/dashboard/works'} element={<Works />} />
          <Route path={'/dashboard/createWork'} element={<CreateWork />} />
          <Route path={'/dashboard/history'} element={<History />} />
          <Route path={'/dashboard/upgradeAccount'} element={<UpgradeAccount />} />
          <Route path={'/dashboard/paymentConfirmation'} element={<PaymentConfirmation />} />
          <Route path={'/dashboard/pay'} element={<Pay />} />
          <Route path={'/dashboard/setting'} element={<Setting />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;