const CronItem = ({ onClick, number, title }) => {
  return (
    <article
      onClick={onClick}
      className="w-full p-2.5 rounded-lg bg-white shadow my-2 flex items-center justify-start m-auto cursor-pointer hover:scale-105 transition-all ease-linear duration-75 active:scale-100 border-r-4 border-teal-600"
    >
      <p className="text-xs md:text-sm text-gray-700 leading-normal">
        <span className="text-sm text-slate-700 ml-1 font-medium">
          {number}
        </span>
        {title}
      </p>
    </article>
  );
};

export default CronItem;
