import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TableUi = ({ column, data, showBtns = true, deleteBtn, current, setCurrent, pages, itemNumber }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);


  const resultIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
  </svg>;


  return (
    <div>
      <div className="relative shadow-md sm:rounded-lg mt-4 overflow-x-auto overflow-y-auto">
        <table className="w-full  text-sm text-left text-gray-500 table-auto min-h-[100px]  ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 text-center border-b ">
            <tr>
              <th scope="col" className="py-3 px-1">
                ردیف
              </th>
              {column?.map((item, index) => {
                return (
                  <th key={index} scope="col" className="py-3 px-6 whitespace-nowrap">
                    {item.name}
                  </th>

                );
              })}
              {showBtns &&
                <>
                  <th scope="col" className="py-3 px-6">
                    ویرایش
                  </th>
                  <th scope="col" className="py-3 px-6">
                    حذف
                  </th>
                  <th scope="col" className="py-3 px-6">
                    تاریخچه
                  </th>
                </>
              }
            </tr>
          </thead>
          <tbody>
            {data?.map((rowData, index) => {

              return (
                <tr key={index} className={`${index % 2 === 0 ? 'bg-indigo-50' : 'bg-white'} cursor-pointer`}>
                  <th className="py-3 px-1 flex items-center justify-center">
                    <p className='border rounded-full p-1 shadow-md w-7 h-7 text-center text-cyan-500 bg-white'>
                      {itemNumber++}
                    </p>
                  </th>
                  {
                    column?.map(({ path, onClick }, index) => {
                      return (
                        <td key={index} className="py-3 px-6 text-center">
                          {onClick
                            ?
                            <button onClick={() => onClick(rowData)}>
                              {resultIcon}
                            </button>
                            :
                            <p className='text-slate-700 font-medium text-sm tracking-wide whitespace-nowrap'>
                              {rowData.address ? rowData[path].substring(0, 50) :
                                rowData[path] || "--"}
                            </p>}
                        </td>
                      );
                    })
                  }
                  {showBtns &&
                    <>
                      < td className="py-3 px-6 text-center" >
                        {/* ---------------- Edit ----------- */}
                        <button onClick={() => navigate(`/dashboard/createWork?id=${rowData.cJobId}`)} className='text-cyan-600 hover:text-cyan-500 active:scale-95' >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                          </svg>
                        </button>
                      </td>
                      <td className="py-3 px-6 text-center ">
                        {/* ----------------- Delete ------------- */}
                        <button
                          onClick={() => setShowModal(!showModal)}
                          // onClick={deleteBtn}
                          className='text-red-600 hover:text-red-500 active:scale-95'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                          </svg>
                        </button>
                        {showModal &&
                          <div className="overflow-y-auto overflow-x-hidden fixed top-1/2 left-1/2  z-50 md:inset-0 h-modal md:h-full bg-gray-600 bg-opacity-60 flex items-center justify-center">
                            <div className="relative p-4 w-full max-w-md h-full md:h-auto">
                              <div className="relative bg-white rounded-lg shadow ">
                                <button onClick={() => setShowModal(false)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-toggle="popup-modal">
                                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                  <span className="sr-only">Close modal</span>
                                </button>
                                <div className="p-6 text-center">
                                  <svg className="mx-auto mb-4 w-14 h-14 text-gray-400 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                  <h3 className="mb-5 text-lg font-normal text-gray-500 ">
                                    کاربر گرامی از پاک کردن این کار مطمئن هستید؟
                                  </h3>
                                  <button
                                    onClick={() => {
                                      return (
                                        deleteBtn(rowData.cJobId),
                                        setShowModal(false)
                                      );
                                    }} data-modal-toggle="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mx-2">
                                    بله، مطمئن هستم
                                  </button>
                                  <button onClick={() => setShowModal(false)} data-modal-toggle="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 mx-2 ">خیر، منصرف شدم</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </td>
                      <td className="py-3 px-6 text-center">
                        {/* ----------------- History */}
                        <button onClick={() => navigate(`/dashboard/history?id=${rowData.cJobId}`)} className='text-cyan-600 hover:text-cyan-500 active:scale-95'>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 9.75h4.875a2.625 2.625 0 010 5.25H12M8.25 9.75L10.5 7.5M8.25 9.75L10.5 12m9-7.243V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185z" />
                          </svg>
                        </button>
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody >
        </table >
      </div >

      <div className='w-6/12 md:w-4/12 grid grid-cols-3 gap-3 mx-auto text-center mt-6'>
        <button className='py-2 px-4 bg-cyan-600 hover:bg-cyan-700 active:scale-95 text-white text-xs font-medium rounded-md' onClick={() =>
          current !== pages && setCurrent((page) => page + 1)
        }>بعدی</button>
        <p className='border rounded-full flex items-center justify-center text-sm font-semibold text-slate-600 w-8 h-8 mx-auto'>{current}</p>
        <button className='py-2 px-4 bg-cyan-600 hover:bg-cyan-700 active:scale-95 text-white text-xs font-medium rounded-md' onClick={() =>
          current !== 1 && setCurrent((page) => page - 1)
        }>قبلی</button>
      </div>


    </div >
  );
};

export default TableUi;