export const dayList = [
   {
    label: 'شنبه',
    id: 6,
  },
  {
    label: 'یکشنبه',
    id: 0,
  },
  {
    label: 'دوشنبه',
    id: 1,
  },
  {
    label: 'سه شنبه',
    id: 2,
  },
  {
    label: 'چهار شنبه',
    id: 3,
  }, {
    label: 'پنج شنبه',
    id: 4,
  }, {
    label: 'جمعه',
    id: 5,
  }
]

export const days = [
  "یکم",
  "دوم",
  "سوم",
  "چهارم",
  "پنجم",
  "ششم",
  "هفتم",
  "هشتم",
  "نهم",
  "دهم",
  "یازدهم",
  "دوازدهم",
  "سیزدهم",
  "چهاردهم",
  "پانزدهم",
  "شانزدهم",
  "هفدهم",
  "هجدهم",
  "نوزدهم",
  "بیستم",
  "بیست و یکم",
  "بیست و دوم",
  "بیست و سوم",
  "بیست و چهارم",
  "بیست و پنجم",
  "بیست و ششم",
  "بیست و هفتم",
  "بیست و هشتم",
  "بیست و نهم",
  "سی ام",
  "سی و یکم",
];

export const monthList = [
  "هر ماه",
  "هر دو ماه",
  "هر سه ماه",
  "هر چهار ماه",
  "هر پنج ماه",
  "هر شش ماه",
  "هر هفت ماه",
  "هر هشت ماه",
  "هر نه ماه",
  "هر ده ماه",
  "هر یازده ماه",
  "هر سال",
];


export const monthNames = [
  "فروردین",
  "اردیبهشت",
  "خرداد",
  "تیر",
  "مرداد",
  "شهریور",
  "مهر",
  "آبان",
  "آذر",
  "دی",
  "بهمن",
  "اسفند",
];
