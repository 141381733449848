import React, { useState, useEffect } from 'react';
import Wrapper from '../../../Wrapper/Wrapper';
import { useGetDataUser, useChangeUserData } from '../../../hooks/useSetting';
import toast from 'react-hot-toast';
import Loading from '../../../Components/Loading/Loading';

const Setting = () => {
  const { data: userAccountData, isLoading: userLoading } = useGetDataUser();
  const { mutate: changeUserData, isLoading: userChangeLoading } = useChangeUserData();
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: '', phoneNumber: '', passWord: '', confirmPassWord: ''
  });

  const changeHandler = (e) => {
    setUserData((prevState) => {
      return ({
        ...prevState,
        [e.target.name]: e.target.value
      });
    });
  };

  useEffect(() => {
    setUserData({ ...userData, email: userAccountData?.email, phoneNumber: userAccountData?.phoneNumber });
  }, [userAccountData]);

  const changeUserDataHandler = () => {
    if (showPassword && userData.passWord !== userData.confirmPassWord) {
      toast.error('رمز عبور وارد شده با تکرار آن یکی نمیباشد');
    } else {
      changeUserData(userData);
    }
  };

  return (
    <Wrapper>
      <div className='h-fit bg-white border rounded-sm p-2 mx-auto my-10 w-11/12'>
        <div className='px-6 py-1.5 border rounded-sm text-sm font-medium shadow-md hover:bg-light active:shadow-sm text-cyan-600 w-fit' >تنظیمات</div>
        {userLoading ? <Loading /> :
          <div className='grid grid-cols-1 gap-2 md:gap-5 place-items-center w-11/12 mt-12 mx-auto'>
            <article className='md:mr-2 w-full md:w-11/12'>
              <p className='text-base text-cyan-900 font-medium'>
                پست الکترونیک
              </p>
              <input name="email" value={userData.email} onChange={changeHandler} className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full sm:w-8/12 md:w-6/12 my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium tracking-wide' type="text" placeholder='example@gmail.com' />
            </article>
            <article className='md:mr-2 w-full md:w-11/12'>
              <p className='text-base text-cyan-900 font-medium'>
                تلفن همراه
              </p>
              <input name="phoneNumber" value={userData.phoneNumber} onChange={changeHandler} className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full sm:w-8/12 md:w-6/12 mt-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium tracking-wide' type="text" disabled />
              <p className='text-xs text-red-600 font-medium mt-1 flex items-center justify-star'> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" className="w-4 h-4 ml-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
              </svg>
                کاربری گرامی شما قادر به تغییر دادن شماره همراه خود نمیباشد</p>
            </article>

            {/* ---------------- toggle btn */}
            <div className='w-11/12 flex items-center justify-start'>
              <button onClick={() => setShowPassword(!showPassword)} className={`${showPassword ? 'border-cyan-600 border-r-4 shadow-md' : 'border-white border-r-4 shadow-sm'} px-4 py-1 text-xs font-medium flex items-center justify-center  rounded-md `}>
                نمایش رمز عبور
                <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zM12 2.25V4.5m5.834.166l-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243l-1.59-1.59" />
                </svg>
                </span>
              </button>
            </div>
            {/* --------------------------- */}

            {showPassword &&
              <>
                <article className='md:mr-2 w-full md:w-11/12'>
                  <p className='text-base text-cyan-900 font-medium'>
                    رمز عبور
                  </p>
                  <input name="passWord" value={userData.passWord} onChange={changeHandler} className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full sm:w-8/12 md:w-6/12 my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium tracking-wide' type="text" placeholder='******' />
                </article>
                <article className='md:mr-2 w-full md:w-11/12'>
                  <p className='text-base text-cyan-900 font-medium'>
                    تکرار رمز عبور
                  </p>
                  <input name="confirmPassWord" value={userData.confirmPassWord} onChange={changeHandler} className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full sm:w-8/12 md:w-6/12 my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium tracking-wide' type="text" placeholder='******' />
                </article>
              </>
            }
            <button onClick={changeUserDataHandler} className="my-6 py-1.5 px-6 text-sm text-white bg-cyan-600 rounded-md shadow-sm active:scale-95">
              ذخیره
            </button>
          </div>
        }
      </div>
    </Wrapper>
  );
};

export default Setting;