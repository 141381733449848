import * as api from "../apis/Reports";
import { useQuery } from 'react-query';


const useGetPeerLogList = (value) => {
  return useQuery('peerLogList', () => api.getPeerLogList(value));
};

const useGetCountLog = (value) => {
  return useQuery('countLogList', () => api.getCountLog(value));
};

const useGetLastJobDone = (value) => {
  return useQuery('lastJobDone', () => api.getLastJobDone(value));
};

const useGetCountJobDone = (value) => {
  return useQuery('countJobDone', () => api.getCountJobDone(value));
};

export { useGetPeerLogList, useGetCountLog, useGetLastJobDone, useGetCountJobDone };