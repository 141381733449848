import client from '../utils/AxiosInterceptor';



const getHistoryLog = async (activePage) => {
  if (activePage.cjobid) {
    const { data } = await client.get(`/Log/List?cjobid=${activePage.cjobid}&page=${activePage.page}&take=${activePage.take}`);
    return data;
  } else {
    const { data } = await client.get(`/Log/List?page=${activePage.page}&take=${activePage.take}`);
    return data;
  }

};

export { getHistoryLog };