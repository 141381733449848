import React, { useState } from 'react';
import * as api from '../../apis/Account';
import * as Api from '../../apis/Captcha';
import './LoginModal.css';
import { toast } from 'react-hot-toast';
import RegisterComp from './RegisterComp';
import LoginComp from './LoginComp';
import { useNavigate } from 'react-router-dom';


const LoginModal = ({ setShowModal }) => {
  const navigate = useNavigate();
  const [register, setRegister] = useState(false);
  const [formValue, setFormValue] = useState({ phoneNumber: '', passWord: '', email: '' });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loginValue, setLoginValue] = useState({ phoneNumber: '', passWord: '' });
  const [showPassword, setShowPassword] = useState(false);



  const changeHandler = (e) => {
    if (e.target.name === 'phoneNumber') {
      if (e.target.value.length < 12) {
        setFormValue((preventState) => {
          return ({
            ...preventState,
            [e.target.name]: e.target.value
          });
        });
      }
    } else {
      setFormValue((preventState) => {
        return ({
          ...preventState,
          [e.target.name]: e.target.value
        });
      });
    }

  };


  const submitHandler = (e) => {
    e.preventDefault();
    if (formValue.phoneNumber === '') {
      toast.error('شماره موبایل خود را وارد نکرده اید');
    } else if (formValue.passWord === '') {
      toast.error('رمز عبور خود را وارد نکرده اید');
    } else {
      api.registerAccount(formValue).then(res => {
        if (res.status) {
          toast.success(res.message);
          setRegister(true);
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const registerHandler = (e) => {
    e.preventDefault();
    if (phoneNumber === '') {
      toast.error('لطفا شماره موبایل خود را وارد کنید');
    } else {
      api.checkMobileNumber({ phoneNumber }).then(res => {
        if (res.status) {
          setLoginValue({ ...loginValue, phoneNumber: res.phoneNumber });
          setShowPassword(true);
        } else {
          toast.error(res.message || 'شماره درست نمیباشد');
        }
      });
      // let dcTextArea = document.getElementById("dc-textarea-response");
      // if (dcTextArea) {
      //   if (dcTextArea.value) {
      //     Api.captchaCheck({
      //       dcTextareaResponse: dcTextArea.value,
      //     }).then(res => {
      //       console.log(res.status)
      //       if (res.data.status) {
      //         api.checkMobileNumber({ phoneNumber }).then(res => {
      //           if (res.status) {
      //             setLoginValue({ ...loginValue, phoneNumber: res.phoneNumber });
      //             setShowPassword(true);
      //           } else {
      //             toast.error(res.message || 'شماره درست نمیباشد');
      //           }
      //         });
      //       }
      //     });
      //   } else {
      //     toast.error("لطفا روی من ربات نیستم کلیک کنید");
      //   }
      // } else {
      //   toast.error("پاسخی دریافت نشد لطفا دوباره تلاش کنید");
      // }


    }
  };

  const loginHandler = (e) => {
    e.preventDefault();
    if (loginValue.passWord === '') {
      toast.error('لطفا نام رمز عبور خود را وارد کنید');
    } else {
      api.loginAccount(loginValue).then(res => {
        if (res.status) {
          toast.success('با موفقیت وارد حساب کاربری شدید');
          localStorage.setItem('_authToken', res.token);
          localStorage.setItem('userType', res.userType);
          navigate("/dashboard");
        }
        else {
          toast.error(res.message || 'عملیات ناموفق');
        }
      });
    }
  };



  const closeIcon = <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>;

  return (
    <div className="login-modal-header">
      <div className="login-modal-container">
        {/* <!-- Modal content --> */}
        <div className="relative bg-white rounded-lg shadow ">
          <button onClick={() => setShowModal(false)} type="button" className="close-btn" data-modal-toggle="authentication-modal">
            {closeIcon}
            <span className="sr-only">Close modal</span>
          </button>
          <div className="py-4 px-6 lg:px-8">
            <h3 className="mb-4 text-base font-medium text-gray-900 text-left">
              {!register ? 'ورود' : 'ثبت نام'}
            </h3>
            {!register ?
              <LoginComp loginValue={loginValue} setLoginValue={setLoginValue} showPassword={showPassword} register={register} setRegister={setRegister} registerHandler={registerHandler} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} changeHandler={changeHandler} loginHandler={loginHandler} />
              :
              <RegisterComp register={register} setRegister={setRegister} submitHandler={submitHandler} formValue={formValue} changeHandler={changeHandler} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;