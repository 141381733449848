
const ModalPermission = ({ isShowModal, setIsShowModal, }) => {


  const closeModal = (e) => {
    e.preventDefault();
    setIsShowModal(false);
  };

  const userPremission = localStorage.getItem("userType");

  return (
    <> {
      isShowModal &&
      <div className="transition-all duration-1000 ease-in-out fixed z-10 top-0 left-0 w-full h-full bg-slate-500 bg-opacity-50 ">
        <div className="absolute bg-white mt-5 w-10/12 sm:w-6/12 xl:w-4-12 h-52   top-1/2 left-1/2 flex items-center justify-center mx-auto -translate-y-1/2 -translate-x-1/2 rounded-lg">
          <div className="flex items-center justify-between text-center flex-col mx-auto w-full h-full p-2">
            <p className="px-2 text-xs md:text-sm lg:text-base text-slate-700  mt-4">
              <span className="ml-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

              </span>
              کاربر گرامی پلن فعال برای حساب کاربری شما از نوع
              {
                userPremission === '0' && <span className="text-teal-500"> رایگان </span> || userPremission === '1' && <span className="text-blue-500"> ویژه </span> || userPremission === '2' && <span className="text-blue-500"> سازمانی </span>
              }
              میباشد اگر مایل به استفاده از این گزینه میباشید لطفا از قسمت ارتقای حساب کاربری اقدام  بفرمایید
            </p>
            <button onClick={closeModal}
              className="px-2 py-1 mb-2 rounded-md text-white bg-blue-500 outline-none shadow-none active:scale-95 text-xs sm:text-sm">متوجه شدم</button>
          </div>

        </div>
      </div>
    } </>
  );
};

export default ModalPermission;
