import React, { useState } from 'react';
import Navbar from '../Components/Navbar/Navbar';
import Sidebar from '../Components/Sidebar/Sidebar';


const Wrapper = ({ children }) => {

  const [showSidebar, setShowSidebar] = useState(true);

  return (
    <div  style={{ background: '#F2F2F2', minHeight: '100vh' }} className="h-full pb-12">
      <Navbar setShowSidebar={setShowSidebar} />
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className={`${showSidebar ? 'sm:mr-52' : 'mr-0'} duration-700`}>
        {children}
      </div>
    </div>
  );
};

export default Wrapper;