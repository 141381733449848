import client from "../utils/AxiosInterceptor";


const registerAccount = async (value) => {
  const { data } = await client.post('/Account/Register', value);
  return data;
};

const checkMobileNumber = async (value) => {
  const { data } = await client.post('/Account/CheckMobile', value);
  return data;
};

const loginAccount = async (value) => {
  const { data } = await client.post('/Account/Login', value);
  return data;
};

export { registerAccount, checkMobileNumber, loginAccount };