import React from 'react';
import TeamWork from '../../../assets/Images/TeamWork.jpg';

const AboutUs = () => {
  return (
    <div id="abountus" className='flex items-center justify-center w-11/12 mx-auto flex-col my-14'>
      <p className='text-cyan-700 font-semibold text-base'>درباره ما</p>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mt-14'>
        <div className='flex items-center justify-start flex-col w-12/12 '>
          <p className='text-base font-medium text-stone-600 h-full leading-relaxed  text-justify mx-auto '>تیم توسعه دهنده چکانه در آبان ماه سال 1400 کار خود را با هدف توسعه و تولید نرم افزارهای کاربری شروع کرد، یکی از محصولات این تیم، چکانه هست، ابزاری کاربردی و مناسب برای همه ی افراد. این پروژه حاصل تلاش افراد تیم ما می باشد ،
            از چپ به راست :
            <span className='text-cyan-500'>کیمیا</span> توسعه دهنده ی فرانت اند ،
            <span className='text-cyan-500'>سینا</span> توسعه دهنده ی فرانت اند ،
            <span className='text-cyan-500'>آردین</span> مدیر پروژه ،
            <span className='text-cyan-500'>سامان</span> دووپس ،
            <span className='text-cyan-500'>میلاد</span> توسعه دهنده ی بک اند</p>
          <button className='text-sm font-medium text-slate-700 border px-6 py-2 rounded mt-4 ml-auto shadow-btn'>تماس با ما</button>
        </div>
        <img src={TeamWork} alt={'Develop Team'} className="rounded-md shadow-lg object-contain" />
      </div>
    </div>
  );
};

export default AboutUs;