import React from 'react';
import { Link } from 'react-router-dom';

const RegisterComp = ({ register, setRegister, formValue, changeHandler, submitHandler, resLoader }) => {
  return (

    <form onSubmit={submitHandler} className="space-y-4" >
      <div>
        <p className="block mb-2 text-sm font-medium text-gray-900 ">شماره موبایل</p>
        <input name="phoneNumber" value={formValue.phoneNumber} onChange={changeHandler} type="number" className="login-input "
        />
      </div>
      <div>
        <p className="block mb-2 text-sm font-medium text-gray-900">رمز عبور</p>
        <input type="password" name="passWord" value={formValue.passWord} onChange={changeHandler} id="password" placeholder="••••••••" className="login-input " />
      </div>
      <button type="submit" className="login-btn">
        ثبت نام
      </button>
      <div className="text-sm font-medium text-gray-500 ">
        <Link onClick={() => setRegister(!register)} to="#" className="text-blue-700 hover:underline py-0.5 flex items-center justify-start">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ml-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
          </svg>

          {!register ? 'ثبت نام' : 'ورود'}
        </Link>
      </div>
    </form>
  );
};

export default RegisterComp;