const CronResult = ({ result, title }) => {
  return (
    <div className="flex items-center justify-center p-2">
      <p className="text-xs md:text-sm text-slate-700 ml-2 whitespace-nowrap px-2">
        {title}
      </p>
      <input
        className="p-2.5 rounded-lg border bg-melo-white text-purple-700 text-xs md:text-sm w-10/12 sm:w-10/12 lg:w-8/12  text-center outline-none"
        placeholder="* * * * * *"
        readOnly
        value={result}
      />
    </div>
  );
};

export default CronResult;
