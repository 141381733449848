import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Wrapper from '../../../Wrapper/Wrapper';

const UpgradeAccount = () => {

  const navigate = useNavigate()
  const [selectPlan, setSelectPlan] = useState(1);

  return (
    <Wrapper>
      <div className='h-screen bg-white border rounded-sm p-2 mx-auto my-10 w-11/12'>
        <div className='px-6 py-1.5 border rounded-sm text-sm font-medium shadow-md hover:bg-light active:shadow-sm text-cyan-600 w-fit' >
          ارتقای حساب کاربری
        </div>
        <p className='mt-6 py-1.5 text-sm font-medium text-slate-700 w-fit' >
        پلن مورد نظر خود را انتخاب کنید
        </p>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mx-auto w-full mt-6'>
          <div onClick={() => setSelectPlan(1)} className={`${selectPlan === 1 ? 'border-cyan-700 border-r-2 shadow-md bg-gray-50' : 'border-white border-r-2 shadow-sm'} cursor-pointer rounded-md p-2 shadow-md w-full h-fit`}>
            <p className='text-slate-700 font-semibold text-sm '>ویژه</p>
            <div className='grid grid-cols-1 gap-2 place-items-start mt-2'>
              <p className='text-xs leading-normal font-medium text-cayn-700 my-2' ><span className='text-teal-500 border rounded px-1'>1.</span> تعداد 10 کار حداکثر</p>
              <p className='text-xs leading-normal font-medium text-cayn-700 my-2' ><span className='text-teal-500 border rounded px-1'>2.</span> تعداد 1000 درخواست در ماه به ازای هر کار</p>
              <p className='text-xs leading-normal font-medium text-cayn-700 my-2' ><span className='text-teal-500 border rounded px-1'>3.</span> استفاده از تمامی قابلیت ها در بازه های زمانی</p>
              <p className='text-xs leading-normal font-medium text-cayn-700 my-2' ><span className='text-teal-500 border rounded px-1'>4.</span> قیمت پلن 20,000 هزار تومان</p>
            </div>
          </div>
          <div onClick={() => setSelectPlan(2)} className={`${selectPlan === 2 ? 'border-cyan-700 border-r-2 shadow-md bg-gray-50' : 'border-white border-r-2 shadow-sm'} cursor-pointer rounded-md p-2 shadow-md w-full h-fit`}>
            <p className='text-slate-700 font-semibold text-sm '>سازمانی</p>
            <div className='grid grid-cols-1 gap-2 place-items-start mt-2'>
              <p className='text-xs leading-normal font-medium text-cayn-700 my-2' ><span className='text-teal-500 border rounded px-1'>1.</span> تعداد نامحدود کار حداکثر</p>
              <p className='text-xs leading-normal font-medium text-cayn-700 my-2' ><span className='text-teal-500 border rounded px-1'>2.</span> تعداد نامحدود درخواست به ازای هر ماه </p>
              <p className='text-xs leading-normal font-medium text-cayn-700 my-2' ><span className='text-teal-500 border rounded px-1'>3.</span> استفاده از تمامی قابلیت ها در بازه های زمانی</p>
              <p className='text-xs leading-normal font-medium text-cayn-700 my-2' ><span className='text-teal-500 border rounded px-1'>4.</span> قیمت پلن 50,000 هزار تومان</p>
            </div>
          </div>
        </div>
        <button onClick={() => navigate('/dashboard/pay')} className='text-xs font-medium text-white bg-cyan-700 hover:bg-cyan-600 py-1 px-6 border mt-8 rounded' >پرداخت</button>
      </div>
    </Wrapper>
  );
};

export default UpgradeAccount;