import React from 'react';
import AboutUs from './AboutUs/AboutUs';
import FooterContainer from './Footer/Footer';
import Header from './Header/Header';
import Navbar from './Navbar/Navbar';
import Plans from './Plans/Plans';
import Property from './Property/Property';
import LoginModal from '../../Components/Login/LoginModal';

const index = () => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showModal, setShowModal] = React.useState(false);

  return (
    <div>
      {showModal && <LoginModal setShowModal={setShowModal} />}
      <Navbar setShowModal={setShowModal} showModal={showModal} />
      <Header />
      <Property />
      <Plans />
      <AboutUs />
      <FooterContainer />
    </div>
  );
};

export default index;