import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { sidebarArray } from './SidebarItems';
import { useLocation } from 'react-router-dom';

const Sidebar = ({ showSidebar, setShowSidebar }) => {

  const location = useLocation() 
  const [url , setUrl] = useState('')
  useEffect(() => {
    setUrl(location.pathname)
  },[location])

  return (
    <aside className={`${showSidebar ? 'mr-0' : '-mr-52'} z-20 w-52 duration-700 fixed top-0 right-0`} aria-label="Sidebar">
      <div className="  h-screen bg-slate-700 rounded ">
        <div className='overflow-y-auto py-4 px-3'>
          <div className='flex items-center justify-between mb-5'>
            <p className='text-base text-teal-400 font-medium'>چکانه</p>
            <button onClick={() => setShowSidebar(false)} className=' border outline-none rounded-md '>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white p-0.5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
          <ul className="space-y-3">
            {sidebarArray?.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.url} className={`${url === item.url && 'bg-slate-600'} flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-slate-600 `}>
                    {item.icon}
                    <span className="mr-3 text-sm font-medium text-white">{item.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className='flex items-center justify-center w-full'>
          <p className='bottom-2 fixed text-cyan-50 text-sm font-medium tracking-wide'>Version 1.2.2</p>
        </div>
      </div>
    </aside>

  );
};

export default Sidebar;