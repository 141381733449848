import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { BASEURL } from "../routes/Constants";
// import * as api from "apis/profile"


const client = axios.create({
  baseURL: BASEURL,
});
client.interceptors.request.use(async (config) => {
  let _authToken = "";
  if (localStorage.getItem("_authToken")) {
    _authToken = "Bearer " + localStorage.getItem("_authToken");
  }
  config.headers.Authorization = _authToken;
  return config;
});
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        window.location.href = "/";
        toast.error('توکن شما منقضی شده است');
        localStorage.removeItem("_authToken");
        localStorage.removeItem("_refreshToken");
      }
    }
    return Promise.reject(error);
  }
);
export default client;
