import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ setShowSidebar }) => {

  const navigate = useNavigate();
  const [showDrop, setShowDrop] = useState(false);
  const [userType, setUserType] = useState(0);

  const exitHandler = () => {
    navigate('/');
    localStorage.clear();
  };

  useEffect(() => {
    setUserType(localStorage.userType);
  }, []);

  return (

    <nav className="bg-slate-700 border-slate-200 px-2 sm:px-4 py-2.5 ">
      <div className="flex items-center justify-between w-full mx-auto">
        <button onClick={() => setShowSidebar(true)} className="flex items-center justify-start ml-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-teal-400">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </button>
        <div className="flex items-center justify-end mr-auto">
          <p className="text-white font-medium text-xs ml-3">کاربر گرامی پلن فعال برای شما <span className="text-teal-400">
            {(userType === "0" && 'رایگان') ||
              (userType === "1" && 'ویژه') ||
              (userType === "2" && 'سازمانی')}
          </span> میباشد</p>
          <button onClick={() => setShowDrop(!showDrop)} className="relative">
            <span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-white hover:scale-105  cursor-pointer">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            </span>
            {showDrop &&
              <ul className='w-36 h-fit border rounded absolute top-12 left-1 bg-white z-50'>
                <li className='w-full hover:bg-gray-50 rounded py-2.5 px-2'>
                  <button className='text-xs font-medium text-slate-600 flex items-center justify-start' onClick={() => navigate('/dashboard/setting')}>تنظیمات</button>
                </li>
                <li className='w-full hover:bg-gray-50 rounded py-2.5 px-2'>
                  <button className='text-xs font-medium text-slate-600 flex items-center justify-start' onClick={() => navigate('/dashboard/upgradeAccount')}>ارتقای حساب کاربری</button>
                </li>
                <li className='w-full hover:bg-gray-50 rounded py-2.5 px-2'>
                  <button className='text-xs font-medium text-slate-600 flex items-center justify-start' onClick={exitHandler}>خروج</button>
                </li>
              </ul>
            }
          </button>
        </div>
      </div>
    </nav>

  );
};

export default Navbar;