import React from 'react';

const Badge = ({ item }) => {
  return (
    <div className='w-full border rounded-md shadow-sm h-32 text-center bg-white flex items-center justify-center flex-col cursor-pointer hover:translate-y-2 duration-700 hover:shadow-md'>
      <p className='mb-auto mt-2 text-cyan-600 font-medium text-sm'>{item.title}</p>
     <div className='border px-10 py-1 shadow-md rounded-md mb-auto min-w-fit'>
     <p className=' text-teal-800 font-medium text-base'>{item.value}</p>
     </div>
    </div>
  );
};

export default Badge;