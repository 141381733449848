import React, { useEffect, useState } from 'react';
import Wrapper from '../../../Wrapper/Wrapper';
import CronExpression from './CronExpression/CronExpression';
import Tabs from './Tabs';
import { useCronExpression, useEditCronExpression } from '../../../hooks/useCronExpression';
import toast from 'react-hot-toast';
import validator from 'validator';
import { useNavigate, useLocation } from 'react-router-dom';
import { useGetJobListWithId } from '../../../hooks/useJob';

const CreateWork = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { mutate: sendCron, data: getCron, isLoading: cronLoading } = useCronExpression();
  const { mutate: editCronMutate, data: editCronDtata, isLoading: cronEditLoading } = useEditCronExpression();
  const { mutate: getJobWithId, data: jobDataWithId } = useGetJobListWithId();

  const [formUrl, setFormUrl] = useState({
    urlToCapture: "",
    urlname: "",
    cronExpresion: "",
    formatSendFile: 0,
    name: "",
    description: "",
    method: 0,
    address: "",
    header: "",
    body: ""
  });
  const [cronValue, setCronValue] = useState({
    timeType: 99,
    jobType: 0,
    runTimeDisplay: "9:00",
    hours: 1,
    minutes: 1,
    dayOfTheMonthToRunOn: 1,
    monthsToRunOn: 1,
    daysToRunDisplay: [0],
    timeOfMonthToRun: 1,
    amountOfMonthsToRunAfter: 1,
  });
  const [jobId, setJobId] = useState('');
  const [cronCode, setCronCode] = useState({ cronExpresion: "", cronDescription: "", });


  const changeHandlerCron = (e) => {
    if (e.target.name === 'method') {
      setFormUrl(prevState => {
        return ({
          ...prevState,
          [e.target.name]: +e.target.value
        });
      });
    } else {
      setFormUrl(prevState => {
        return ({
          ...prevState,
          [e.target.name]: e.target.value
        });
      });
    }

  };


  useEffect(() => {
    setJobId(location?.search.split('=')?.[1]);
  }, [location.pathname]);

  useEffect(() => {
    if (jobId !== '') {
      getJobWithId(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    setFormUrl(jobDataWithId?.job);
    setCronCode({ cronExpresion: jobDataWithId?.job?.cronExpresion, cronDescription: jobDataWithId?.job?.cronDescription });
  }, [jobDataWithId]);


  const sendUrlName = () => {
    let isTrueUrl = validator.isURL(formUrl?.address, {
      protocols: [
        'http', 'https',
      ],
      require_protocol: true,
    });
    if (formUrl?.address === '') {
      toast.error('آدرس وبسایت وارد نشده است');
    }
    else if (!isTrueUrl) {
      toast.error('فرمت آدرس وبسایت وارد شده صحیح نمیباشد');
    }
    else if (formUrl?.name === '') {
      toast.error('نام وبسایت وارد نشده است');
    } else if (location?.search) {
      editCronMutate({ ...formUrl, ...cronValue, ...cronCode, cJobId: jobId });
      toast.success('کار مورد نظر با موفقیت تغییر یافت.');
    }
    else {
      editCronMutate({ ...formUrl, ...cronValue, ...cronCode });
      setFormUrl({
        urlToCapture: "",
        urlname: "",
        cronExpresion: "",
        formatSendFile: 0,
        name: "",
        description: "",
        method: 0,
        address: "",
        header: "",
        body: ""
      });
      toast.success('کار مورد نظر با موفقیت ثبت شد.');
    }

  };



  return (
    <Wrapper>
      <div className='h-fit bg-white border rounded-sm p-2 mx-auto my-10 w-11/12'>
        <button className='px-6 py-1.5 border rounded-sm text-sm font-medium shadow-md hover:bg-light active:shadow-sm text-cyan-600' >ایجاد کار</button>
        <div className='grid grid-cols-1 gap-5 my-6 border-b '>
          <article className='md:mr-2'>
            <p className='text-base text-cyan-900 font-medium'>چه کاری میخواهید انجام دهید؟</p>
            <input name="name" value={formUrl?.name} onChange={changeHandlerCron} className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full md:w-6/12 my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium' type="text" placeholder='مثال: آنالیز رمز ارز ها در یک سال اخیر' />
          </article>
          <article className='md:mr-2'>
            <p className='text-base text-cyan-900 font-medium'>توضیحات خود را در این قسمت وارد کنید</p>
            <textarea name="description" value={formUrl?.description} onChange={changeHandlerCron} rows='4' className='border outline-none rounded-md border-cyan-200 focus:border-cyan-600 px-2 py-2 w-full md:w-6/12 my-4 placeholder:text-xs placeholder:text-slate-400 text-sm text-slate-600 font-medium' placeholder='توضیحات' ></textarea>
          </article>
        </div>
        <div className='border-b my-10'>
          <Tabs formUrl={formUrl} changeHandler={changeHandlerCron} />
        </div>
        <div className='border-b mt-60 sm:mt-30 md:mt-10 mb-10'>
          <CronExpression formUrl={formUrl}
            setFormUrl={setFormUrl}
            cronCode={cronCode}
            setCronCode={setCronCode}
            cronValue={cronValue}
            setCronValue={setCronValue}
            sendCron={sendCron}
            getCron={getCron}
          />
        </div>
        <div className='flex items-center justify-center mx-auto w-full my-6'>
          <button onClick={sendUrlName} className='py-1 px-4 rounded shadow-sm bg-cyan-600 text-white text-sm hover:bg-cyan-700'>ذخیره</button>
        </div>
      </div>
    </Wrapper>
  );
};

export default CreateWork;