import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);




export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    // title: {
    //   display: true,
    //   text: 'آمار کارهای انجام شده در یک سال اخیر',
    // },
  },
};



const BarChart = ({ value }) => {


  const labels = value?.report?.map(item => item.item1) || [];

  const data = {
    labels,
    datasets: [
      {
        label: 'انجام شده',
        data: value?.report?.map(item => item.item2) || [],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'در حال انجام',
        data: value?.report?.map(item => item.item3) || [],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <div className='border rounded-md bg-white shadow-sm p-2 flex items-center justify-center flex-col'>
      <p className='text-sm font-medium text-cyan-600 mx-auto my-2'>آمار کار انجام شده در یک سال اخیر</p>
      <Bar options={options} data={data} />;
    </div>
  );
};

export default BarChart;